<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <!-- form -->
          <b-card-title
            title-tag="h1"
            class="font-weight-bolder mb-1 text-center pt-3"
          >
            Importar cliente mediante XLSX
          </b-card-title>
          <validation-observer ref="importClient">
            <div class="asignar-trabajos-modal">
              <b-row
                class="justify-content-center middle"
                align-v="center"
              >
                <b-col
                  class="text-center"
                  lg="6"
                >
                  <span>Debe utilizar la plantilla proporcionada o no se importara el cliente correctamente</span>
                  <a
                    href="/xlsx/PlantillaImportación.xlsx"
                    download
                  >
                    Plantilla
                  </a>
                </b-col>
              </b-row>

              <b-form
                class="mt-2"
                @submit.prevent="handleSubmitImportClient"
                @keydown.enter="handleSubmitImportClient"
              >
                <b-container class="pl-3 pr-3">
                  <b-row
                    class="justify-content-center middle"
                    align-v="center"
                  >
                    <b-col lg="4">
                      <b-form-group
                        :label="'Archivo'"
                        label-for="csv"
                      >
                        <ImageDropzone
                          ref="excel"
                          max-files="1"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>
                  <b-row class="text-center mb-3">
                    <b-col>
                      <b-button
                        type="submit"
                        variant="primary"
                        class="mt-1 mr-1 text-uppercase"
                      >
                        {{ 'Importar' }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                      class="justify-content-center middle mb-5"
                      align-v="center"
                  >
                    <b-col md="8">
                      <span class="text-red">{{ chargeErrors }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </div>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import {
  BButton,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BLink,
  BRow,
  BCard,
} from 'bootstrap-vue'
import { empty } from 'leaflet/src/dom/DomUtil'

export default {
  name: 'UploadCSV',
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ImageDropzone,
    BLink,
    BCardTitle,
    BContainer,
  },
  data() {
    return {
      excel: '',
    }
  },
  computed: {
    ...mapGetters({
      chargeErrors: 'clients/getError',
    }),
  },
  methods: {
    ...mapActions({
      import: 'clients/import',
    }),
    handleSubmitImportClient() {
      this.$refs.importClient.validate().then(success => {
        if (success) {
          const formData = this.createFormDataUpload()
          if (formData) {
            this.import({ excel: formData })
          }
        }
      })
    },
    createFormDataUpload() {
      return this.$refs.excel.getFormData('excel')
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
